import { ResourceCode } from '@/const/code'

export default [
  {
    path: '/studyProcess',
    name: 'studyProcess',
    component: () => import('@/views/study/Process.vue'),
    meta: {
      resource: ResourceCode.PORTAL_STUDY_PROCESS,
    },
  },
  {
    path: '/learningOutcome',
    name: 'learningOutcome',
    component: () => import('@/views/study/learning-outcome/LearningOutcome.vue'),
    meta: {
      resource: ResourceCode.PORTAL_LEARNING_OUTCOME,
    },
  },
  {
    path: '/studentSchedule',
    name: 'studentSchedule',
    component: () => import('@/views/study/schedule/StudentSchedule.vue'),
    meta: {
      resource: 'PORTAL_SCHEDULE_STUDENT',
    },
  },
  {
    path: '/studentScheduleV2',
    name: 'studentScheduleV2',
    component: () => import('@/views/study/scheduleV2/StudentScheduleV2.vue'),
    meta: {
      resource: 'PORTAL_SCHEDULE_STUDENT_V2',
    },
  },
  {
    path: '/creditClass',
    name: 'creditClass',
    component: () => import('@/views/study/credit-class/CreditClass.vue'),
    meta: {
      resource: 'PORTAL_CREDIT_CLASS',
    },
  },
  {
    path: '/registerDiploma',
    name: 'registerDiploma',
    component: () => import('@/views/study/register_diploma/RegisterDiploma.vue'),
    meta: {
      resource: 'PORTAL_REGISTER_DIPLOMA',
    },
  },
  {
    path: '/class',
    name: 'class',
    component: () => import('@/views/study/class/Class.vue'),
    meta: {
      resource: 'PORTAL_CLASS',
    },
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import('@/views/documents/index.vue'),
    meta: {
      pageTitle: 'Tài liệu',
      action: 'READ',
      resource: 'PORTAL_DOCUMENTS',
    },
  },
  {
    path: '/programmes',
    name: 'listProgramme',
    component: () => import('@/views/programme/Programme.vue'),
    meta: {
      pageTitle: 'Tài liệu',
      action: 'READ',
      resource: 'PORTAL_PROGRAMME',
    },
  },
  {
    path: '/practicalResearchOutcome',
    name: 'practicalResearchOutcome',
    component: () => import('@/views/practical-research/PracticalResearchOutcome.vue'),
    meta: {
      pageTitle: 'Nghiên cứu thực tế',
      action: 'READ',
      resource: 'PORTAL_PRACTICAL_RESEARCH_OUTCOME',
    },
  },
]
