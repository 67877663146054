<template>
  <b-modal
    id="studentClassModal"
    centered
    title="Danh sách học viên"
    size="xl"
    body-class="position-static"
    hide-footer
    @shown="onShown"
  >
    <b-row class="mt-2">
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="dataSources"
          :pagination-options="paginationOptions"
          :line-numbers="true"
        >
          <div
            slot="emptystate"
            style="text-align: center; font-weight: bold"
          >
            Không có bản ghi nào !
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'status'"
              class="text-nowrap"
            >
              {{ getStatusName(props.row.status) }}
            </span>
            <!-- Column: Common -->
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Hiển thị 1 đến </span>
                <b-form-select
                  v-model="itemsPerPage"
                  :options="itemsPerPageOptions"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap">của {{ props.total }} bản ghi</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="itemsPerPage"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                />
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>

    <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
      no-wrap
    />
  </b-modal>
</template>

<script>
import {
  BCol, BFormSelect, BModal, BOverlay, BPagination, BRow, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import { STUDENT_CLASS_STATUSES } from '@/const/selection'

export default {
  name: 'StudentClass',
  components: {
    BModal,
    BCol,
    BRow,
    BFormSelect,
    BOverlay,
    BPagination,
    VueGoodTable,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    classId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã học viên',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã học viên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Họ tên',
          field: 'fullName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập họ tên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: STUDENT_CLASS_STATUSES.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Chọn trạng thái',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'study/studentsClass',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
  },
  methods: {
    ...mapActions({
      getStudentsByClass: 'study/getStudentsByClass',
    }),
    async onShown() {
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getStudentsByClass({ classId: this.classId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getStatusName(id) {
      return STUDENT_CLASS_STATUSES.find(status => status.value === id).label
    },
  },
}
</script>
