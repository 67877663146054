<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            sm="12"
            md="3"
            lg="3"
            xl="3"
          >
            <b-form-group
              label-for="semester"
            >
              <v-select
                id="semester"
                v-model="courseSemesterId"
                placeholder="Học kỳ"
                label="name"
                :clearable="false"
                :options="courseSemesters"
                :reduce="(option) => option.id"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <vue-good-table
              :columns="columns"
              :rows="dataSources"
              :pagination-options="paginationOptions"
              :line-numbers="true"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'status'">
                  {{ getStatusName(props.row.status) }}
                </span>
                <span v-else-if="props.column.field === 'action'">
<!--                  <b-dropdown-->
<!--                    variant="link"-->
<!--                    no-caret-->
<!--                    :right="$store.state.appConfig.isRTL"-->
<!--                  >-->
<!--                    <template #button-content>-->
<!--                      <feather-icon-->
<!--                        icon="MoreVerticalIcon"-->
<!--                        size="16"-->
<!--                        class="align-middle text-body"-->
<!--                      />-->
<!--                    </template>-->
<!--                    <b-dropdown-item-->
<!--                      v-if="creditClassDocumentShowable"-->
<!--                      @click="onShowCreditClassDocumentModal(props.row.id)"-->
<!--                    >-->
<!--                      <feather-icon icon="BookIcon" />-->
<!--                      <span class="align-middle ml-50">Giáo trình</span>-->
<!--                    </b-dropdown-item>-->
<!--                    <b-dropdown-item-->
<!--                      @click="onShowCreditClassNotificationModal(props.row.id)"-->
<!--                    >-->
<!--                      <feather-icon icon="Volume2Icon" />-->
<!--                      <span class="align-middle ml-50">Thông báo</span>-->
<!--                    </b-dropdown-item>-->
<!--                  </b-dropdown>-->
                  <span v-b-tooltip.hover title="Chi tiết học phần" placement="top" class="btn-icon" size="sm" style="cursor: pointer; margin-right: 6px"
                        @click="onShowCreditClassDetailModal(props.row.id)">
                           <feather-icon icon="EyeIcon" size="23" class="stroke-current"/>
                   </span>
                  <span v-b-tooltip.hover title="Giáo trình/Tài liệu" placement="top" class="btn-icon" size="sm" style="cursor: pointer"
                            @click="onShowCreditClassDocumentModal(props.row.id)">
                           <feather-icon icon="BookIcon" size="23" class="text-primary stroke-current"/>
                   </span>
                  <span v-b-tooltip.hover title="Thông báo" placement="top" class="btn-icon" style="margin-left: 6px; cursor: pointer" size="sm"
                            @click="onShowCreditClassNotificationModal(props.row.id)">
                      <feather-icon :badge="props.row.countNotification"
                                    badge-classes="bg-danger"
                                    icon="BellIcon"
                                    size="23"
                                    class="text-info stroke-current"
                      />
                  </span>

                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị 1 đến </span>
                    <b-form-select
                      v-model="itemsPerPage"
                      :options="itemsPerPageOptions"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="itemsPerPage"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    />
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <CreditClassDocument :credit-class-id="creditClassId" />
    <CreditClassNotification :credit-class-id="creditClassId" />
    <CreditClassDetail :credit-class-id="creditClassId" />
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCol, BFormGroup, BOverlay, BRow, BPagination, BFormSelect, VBTooltip,
} from 'bootstrap-vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { CREDIT_CLASS_STATUSES } from '@/const/selection'
import CreditClassDocument from '@/views/study/credit-class/CreditClassDocument.vue'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import CreditClassNotification from '@/views/study/credit-class/CreditClassNotification.vue'
import CreditClassDetail from '@/views/study/credit-class/CreditClassDetail.vue'

export default {
  name: 'CreditClass',
  components: {
    BFormSelect,
    BPagination,
    CreditClassNotification,
    CreditClassDocument,
    CreditClassDetail,
    BCol,
    BRow,
    BCard,
    BCardBody,
    BOverlay,
    BFormGroup,
    VueGoodTable,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã lớp học',
          field: 'creditClassCode',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Mã học phần',
          field: 'subjectCode',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tên học phần',
          field: 'subjectName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên học phần',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Giảng viên',
          field: 'teachers',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên giảng viên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: CREDIT_CLASS_STATUSES.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Chọn trạng thái',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      courseSemesterId: null,
      creditClassId: null,
    }
  },
  computed: {
    ...mapGetters({
      courseSemesters: 'study/courseSemesters',
      dataSources: 'study/creditClasses',
    }),
    creditClassDocumentShowable() {
      return hasPermissionForResource(PermissionCode.READ, ResourceCode.CREDIT_CLASS_DOCUMENT)
    },
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
  },
  watch: {
    async courseSemesterId(val) {
      await this.getDataSourceFromStore(val)
    },
  },
  async created() {
    await this.getCourseSemestersFormStore()
    const { groupId } = this.$route.query
    // eslint-disable-next-line radix
    if (groupId) {
      this.onShowCreditClassNotificationModal(groupId)
    }
  },
  beforeRouteUpdate(to, from, next) {
    const newGroupId = to.query.groupId
    if (newGroupId) {
      this.onShowCreditClassNotificationModal(newGroupId)
    }
    next() // Tiếp tục điều hướng
  },
  methods: {
    ...mapActions({
      getCourseSemestersByProgramme: 'study/getCourseSemestersByProgramme',
      getCreditClassesByCourseSemester: 'study/getCreditClassesByCourseSemester',
    }),
    async getCourseSemestersFormStore() {
      const programmeId = this.user.portalProgrammeId
      if (!programmeId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Học viên không theo học chương trình đào tạo nào',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        await this.getCourseSemestersByProgramme({ programmeId })
        if (this.courseSemesters.length > 0) {
          this.courseSemesterId = this.courseSemesters[0].id
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async getDataSourceFromStore(courseSemesterId) {
      this.isLoading = true
      try {
        await this.getCreditClassesByCourseSemester({ studentId: this.user.studentId, courseSemesterId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getStatusName(id) {
      return CREDIT_CLASS_STATUSES.find(status => status.value === id).label
    },
    onShowCreditClassDetailModal(id) {
      this.creditClassId = id
      this.$bvModal.show('creditClassDetailModal')
    },
    onShowCreditClassDocumentModal(id) {
      this.creditClassId = id
      this.$bvModal.show('creditClassDocumentModal')
    },
    onShowCreditClassNotificationModal(id) {
      this.creditClassId = id
      this.$bvModal.show('creditClassNotificationModal')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select";
@import '@core/scss/vue/libs/vue-good-table';
</style>
