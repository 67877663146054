<template>
  <div class="container-fluid p-0 m-0">
    <b-row>
      <b-col cols="12" class="p-0">
        <b-card no-body class="m-0">
          <b-card-header>
            <b-card-title class="text-center">
              <h1 class="text-success text-capitalize">Thanh toán học phí</h1>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <h3 :class="paymentResponseDescriptionStyle">{{paymentResponseDescription}}</h3>
            <div class="demo-space-y" v-if="(this.confirmStatus < 2)">
              <b-progress max="100">
                <b-progress-bar
                  :variant="(initStatus >= 1) ? 'success' : 'warning'"
                  :value="20"
                  :striped="(initStatus >= 1)"
                  :animated="(initStatus >= 1 && (initStatus+transferStatus+confirmStatus < 6))"
                  :label="(initStatus === 1) ? 'Khởi tạo giao dịch' : ''"
                />
                <b-progress-bar
                  :variant="(transferStatus >= 1) ? 'success' : 'warning'"
                  :value="40"
                  :striped="(transferStatus >= 1)"
                  :animated="(transferStatus >= 1 && (initStatus+transferStatus+confirmStatus < 6))"
                  :label="(transferStatus === 1) ? 'Thực hiện giao dịch' : ''"
                />
                <b-progress-bar
                  :variant="(confirmStatus >=1) ? 'success' : 'warning'"
                  :value="40"
                  :striped="(confirmStatus >= 1)"
                  :animated="(confirmStatus >= 1 && (initStatus+transferStatus+confirmStatus < 6))"
                  :label="(confirmStatus === 1) ? 'Chờ xác nhận kết quả giao dịch' : ''"
                />
              </b-progress>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow, BProgressBar, BProgress,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Payment',
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BProgressBar,
    BProgress,
  },
  props: {
    infoReception: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      windowTransaction: {},
      initStatus: 0,
      transferStatus: 0,
      confirmStatus: 0,
      popupUrl: '',
      interval: undefined,
      paymentResponseDescription: 'Đang thực hiện giao dịch...Vui lòng không đóng trình duyệt!',
      paymentResponseDescriptionStyle: 'text-primary',
    }
  },
  computed: {
    ...mapGetters({
      payment: 'payment/payment',
      queryPaymentStatus: 'payment/queryPaymentStatus',
    }),
    getConfirmStatus() {
      return this.confirmStatus
    },
  },
  async mounted() {
    await this.initTransaction()
  },
  methods: {
    ...mapActions({
      initPayment: 'payment/initPayment',
      getPaymentByTransactionId: 'payment/getPaymentByTransactionId',
      queryPayment: 'payment/queryPayment',
    }),

    openPopup(data) {
      const myURL = data.url
      const myWidth = 900
      const myHeight = 600

      // eslint-disable-next-line no-restricted-globals
      const left = (screen.width - myWidth) / 2
      // eslint-disable-next-line no-restricted-globals
      const top = (screen.height - myHeight) / 4

      // eslint-disable-next-line no-unused-vars
      const myWindow = window.open(
        myURL,
        'web',
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=${myWidth}, height=${myHeight}, top=${top}, left=${left}`,
      )
      return myWindow
    },

    handleNotPaid(data) {
      return data && data.receipt_status === 1 ? data.total : 0 + data && data.receipt_ktk_status === 1 ? data.total_ktk : 0
    },

    async initTransaction() {
      const moneyNotPaid = this.handleNotPaid(this.infoReception)
      if (moneyNotPaid > 0) {
        const request = {
          receiptId: this.infoReception.receipt_id,
          receiptKtkId: this.infoReception.receipt_ktk_id ? this.infoReception.receipt_ktk_id : null,
        }
        // call api initTransaction
        try {
          this.initStatus = 1
          const res = await this.initPayment(request)
          if (res.isSuccessful && res.url !== '') {
            this.initStatus = 2
            this.transferStatus = 1
            // open Popup payment
            const data = {
              url: res.url,
            }
            this.popupUrl = res.url
            this.windowTransaction = this.openPopup(data)
            // wait done -> close popup
            // eslint-disable-next-line no-use-before-define
            this.interval = setInterval(async () => {
              if (this.windowTransaction.closed) {
                this.transferStatus = 2
                this.confirmStatus = (this.confirmStatus === 0) ? 1 : this.confirmStatus
              }
              if (this.confirmStatus >= 2) {
                clearInterval(this.interval)
              } else {
                const uri = this.popupUrl
                const params = new URLSearchParams(uri)
                const transactionId = params.get('transactionId')
                if (transactionId && (this.transferStatus === 1 || this.confirmStatus === 1)) {
                  // await this.getPaymentByTransactionId(transactionId)
                  await this.queryPayment(transactionId)

                  if (this.queryPaymentStatus.transactionStatus !== '1') {
                    await this.getPaymentByTransactionId(transactionId)

                    this.confirmStatus = 2
                    if (this.payment.status === 0 && this.payment.payDate !== '' && this.payment.responseCode === '00'
                    ) {
                      this.paymentResponseDescriptionStyle = 'text-success'
                      this.paymentResponseDescription = `Chúc mừng! Bạn đã thanh toán thành công khoản phí ${this.payment.merchantOrderId}!`
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: this.paymentResponseDescription,
                          icon: 'CheckIcon',
                          variant: 'success',
                        },
                      })
                    } else {
                      this.paymentResponseDescriptionStyle = 'text-danger'
                      this.paymentResponseDescription = `Rất tiếc, giao dịch không thành công!
                      ${(this.payment.responseDescription && this.payment.responseDescription === 'Transaction done')
                          ? this.queryPaymentStatus.errorDetail : this.payment.responseDescription}`
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: this.paymentResponseDescription,
                          icon: 'XCircleIcon',
                          variant: 'danger',
                        },
                      })
                    }
                    this.$emit('success')
                  }
                }
              }
            }, 3000)
            setTimeout(() => {
              // CHeck if timeout -> stop
              this.windowTransaction.close()
              clearInterval(this.interval)
              // eslint-disable-next-line no-cond-assign
              if (this.confirmStatus = 2) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Giao dịch thất bại do vượt quá thời gian cho phép!',
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
              this.$emit('success')
            }, 610000)
          }
        } catch (e) {
          this.closeModalPayment()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Rất tiếc, đã có lỗi xảy ra, vui lòng thử lại sau!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          console.error('ERR: ', e)
        }
      }
    },

    closeModalPayment() {
      this.$bvModal.hide('modal-transaction')
    },
  },
}
</script>

<style lang="scss">
  #modal-transaction .progress {
    display: flex;
    height: 2rem;
    overflow: hidden;
    line-height: 0;
    font-size: 1.2rem;
  }
  #modal-transaction .modal-body{
    padding: 0;
    margin: 0;
  }
</style>
