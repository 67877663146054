<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <vue-good-table
              mode="remote"
              :columns="columns"
              :rows="dataSources"
              :pagination-options="paginationOptions"
              :line-numbers="true"
              :row-style-class="rowStyleClassFn"
              @on-row-click="onRowClick"
              @on-column-filter="onColumnFilter"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span>{{ props.formattedRow[props.column.field] }}</span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị 1 đến </span>
                    <b-form-select
                      v-model="itemsPerPage"
                      :options="itemsPerPageOptions"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="itemsPerPage"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    />
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <Score :student-examination="studentExamination" />
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BOverlay,
  BRow,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import Score from './Score.vue'

export default {
  name: 'LearningOutcome',
  components: {
    BPagination,
    BFormSelect,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    VueGoodTable,
    Score,
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      filter: {
        studentId: null,
        programmeId: null,
        courseSemesterId: null,
      },
      studentExamination: undefined,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'study/learningOutcomes',
      courseSemesters: 'study/courseSemesters',
    }),
    columns() {
      return [
        {
          label: 'Học phần',
          field: 'subjectName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên học phần',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Số tín chỉ',
          field: 'totalCreditNum',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập số tín chỉ',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          sortFn: this.sortFn,
        },
        {
          label: 'Điểm tổng kết',
          field: 'averagePoint',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập điểm số',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Điểm chữ',
          field: 'alphabetPoint',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semesterName',
          sortable: true,
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.semesterFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          thClass: 'text-center',
        },
      ]
    },
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    semesterFilterDropdownItems() {
      return this.courseSemesters.map(item => ({ value: item.id, text: item.name }))
    },
  },
  async created() {
    this.filter.studentId = this.user.studentId
    this.filter.programmeId = this.user.portalProgrammeId
    this.isLoading = true
    try {
      await Promise.all([
        this.getLearningOutcome(this.filter),
        this.getCourseSemestersByProgramme({ programmeId: this.user.portalProgrammeId }),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getLearningOutcome: 'study/getLearningOutcome',
      getCourseSemestersByProgramme: 'study/getCourseSemestersByProgramme',
    }),
    sortFn(x, y) {
      if (x < y) return -1
      return x > y ? 1 : 0
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    onRowClick(param) {
      const { row } = param
      this.studentExamination = row
      this.$bvModal.show('scoreModal')
    },
    rowStyleClassFn(row) {
      return this.studentExamination && row.studentCreditClassId === this.studentExamination.studentCreditClassId ? 'row-selected' : ''
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getLearningOutcome(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'semesterName')) this.updateParams({ courseSemesterId: columnFilters.semesterName })
      await this.getMainDataFromStore()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table';
.status-review{
  background-color: rgb(40, 167, 69);
  padding: 5px;
  color: white;
  border-radius: 5px;
}
.padding-5 {
  padding: 5px;
}
.br-5 {
  border-radius: 5px;
}
.row-selected {
  background-color: rgba(251, 205, 68, 0.89);
  color: #FFF;
}
</style>
