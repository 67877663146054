<template>
  <b-container fluid>
    <b-card>
      <b-overlay>
        <div class="m-2">
          <b-row>
            <b-col
                cols="8"
                md="8"
            >
              <h3>Danh sách văn bằng/ chứng chỉ của học viên</h3>
            </b-col>
            <b-col class="text-right">
              <b-button-group>
                <b-button
                    v-b-modal.createCredentialConditionModal
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    size="sm"
                    @click="onCreateCredentialCondition()"
                >
                  <feather-icon icon="PlusIcon" />
                  Tạo yêu cầu
                </b-button>

              </b-button-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  style-class="vgt-table striped bordered"
                  :columns="columnsCertificate"
                  :rows="credentialsOfStudent"
                  :line-numbers="true"
              >
                <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>

                <template
                    slot="table-row"
                    slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>

                  <span
                      v-if="props.column.field === 'action'"
                      class="thead-group-action"
                  >
                    <b-button-group>
                      <b-button
                          v-b-modal.createCredentialConditionModal
                          class="btn-icon"
                          variant="primary"
                          size="sm"
                          @click="onCreateCredentialCondition(props.row)"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>

                    </b-button-group>
                  </span>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <CreateCredentialCondition
        ref="createCredentialConditionModal"
        :item="currentCredentialOfStudent"
        @succeed=""
    />
  </b-container>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormGroup, BFormSelect, BOverlay, BPagination, BRow, BModal,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CreateCredentialCondition from '@/views/certificate/CreateCredentialCondition.vue'

export default {
  name: 'CredentialsOfStudent',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    CreateCredentialCondition,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
    credentialType: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      currentCredentialOfStudent: undefined,
      columnsCertificate: [
        {
          label: 'Tên học viên',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngày tháng năm sinh',
          field: 'birthday',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nơi sinh',
          field: 'address',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Dân tộc',
          field: 'ethnic',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Tên chứng chỉ',
          field: 'credentialName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Năm cấp',
          field: 'year',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số hiệu chứng chỉ',
          field: 'code',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số vào sổ gốc',
          field: 'codeCredentialStorage',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      columnsDegree: [
        {
          label: 'Tên học viên',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngày tháng năm sinh',
          field: 'birthday',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nơi sinh',
          field: 'address',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Dân tộc',
          field: 'ethnic',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Quốc tịch',
          field: 'nationality',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Tên văn bằng',
          field: 'credentialName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Năm cấp',
          field: 'year',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Xếp loại',
          field: 'graduationText',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số hiệu văn bằng',
          field: 'code',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số vào sổ gốc',
          field: 'codeCredentialStorage',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      credentialsOfStudentTest: [
        {
          studentId: 276,
          codeStudent: '2552020474',
          name: 'Nguyễn Thị Thu An',
          major: 'Sư phạm tiểu học',
          ethnic: 'Kinh',
          gender: 'Không xác định',
          address: 'Ninh Bình',
          birthday: '25/05/2003',
          nationality: 'Việt Nam',
          credentialName: 'Chứng chỉ ngoại ngữ',
          codeCredentialStorage: 'abc1232024',
          code: 'abc123',
          year: '2021-2022',
          graduationText: 'Giỏi',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      credentialsOfStudent: 'credentialOfStudent/credentialsOfStudent',
      statuses: 'credentialOfStudent/statuses',
    }),
  },
  async created() {
    await this.readStudentsCredentialStorage({
      credentialId: this.item.id,
      currentPage: 1,
      itemsPerPage: 1000,
      credentialType: this.credentialType,
      studentId: this.student.studentId,
    })
  },
  methods: {
    ...mapActions({
      readStudentsCredentialStorage: 'credentialOfStudent/readStudentsCredentialStorage',
    }),
    onCreateCredentialCondition(row) {
      if(row){
        this.currentCredentialOfStudent = row
      }else{
        this.currentCredentialOfStudent = {credentialId: this.item.id}
      }
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
  },
}
</script>

<style scoped lang="scss">

</style>
