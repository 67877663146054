<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <Logo />

            <h2 class="brand-text text-primary ml-1">
              {{ appName }}
            </h2>
          </b-link>
          <b-card-text class="mb-2 text-center">
            👋 Chào mừng bạn đến với Cổng thông tin học viên!
            Vui lòng đăng nhập để tiếp tục
          </b-card-text>

          <validation-observer
            ref="loginFormRef"
            #default="{invalid}"
          >
            <b-form class="auth-login-form mt-2">
              <b-form-group
                label-for="username"
                label="Tài khoản"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tài khoản"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    name="username"
                    :state="getElementState(errors)"
                    autofocus
                    @keyup.enter="onLogin"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Mật khẩu</label>
                  <b-link :to="{name:'forgotPassword'}">
                    <small>Quên mật khẩu?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Mật khẩu"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="getElementState(errors)"
                      name="password"
                      @keyup.enter="onLogin"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                :disabled="invalid"
                @click="onLogin"
              >
                Đăng nhập
              </b-button>

              <b-button
                variant="warning"
                block
                @click="onLoginSso"
              >
                Đăng nhập SSO
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import { LOGIN_TYPE } from '@/const/type'

export default {
  name: 'Login',
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    Logo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      password: '',
      status: '',
      isLoading: false,
      required,
      appName: process.env.VUE_APP_NAME,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      verify: 'auth/verify',
      updatePortalProgramme: 'layout/updatePortalProgramme',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onLogin() {
      const valid = await this.$refs
        .loginFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          await this.$recaptchaLoaded()
          const recaptchaToken = await this.$recaptcha('login')
          await this.login({
            username: this.username,
            password: this.password,
            loginType: +LOGIN_TYPE.NORMAL,
            recaptchaToken,
          })
          const user = await this.verify()
          if (user) {
            // this.$socket.emit('register_server_socket', user.studentId)
            this.$ability.update(user.abilities)
            if (user.programmes.length > 0 && !user.portalProgrammeId) {
              await this.updatePortalProgramme({
                studentId: user.studentId,
                programmeId: user.programmes[0].id,
              })
            }
            try {
              await this.$router.replace({ name: 'portalDashboard' })
            } catch (e) {
              // empty
            }
          } else {
            useJwt.handleLogout()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Không lấy được thông tin người dùng',
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    onLoginSso() {
      const { protocol, hostname } = window.location
      const redirectUri = `${protocol}//${hostname}/auth/login/ssoCallback&scope=openid+profile&nonce=asd`
      window.location.href = `${process.env.VUE_APP_SSO_URL}/oauth2/authorize?response_type=code+id_token+token&client_id=${process.env.VUE_APP_SSO_CLIENT_ID}&redirect_uri=${redirectUri}`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
