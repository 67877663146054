<template>
  <div v-if="conditionRegisterGraduationProject">
    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Danh sách các đề tài khóa luận tốt nghiệp mở đăng ký
              <span
                v-if="!isAllowRegister"
                style="color: red; font-style: italic;"
              >
                (Hết thời gian đăng ký khóa luận tốt nghiệp [{{ courseSemesterCurrent.openRegisterAt }} - {{ courseSemesterCurrent.closeRegisterAt }}] )
              </span>
            </b-card-title>
          </b-card-header>

          <b-card-body>
            <vue-good-table
              :columns="registerColumns"
              :rows="dataRows1"
              :pagination-options="paginationOptions"
              :line-numbers="true"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'capacity'">
                  {{ props.row.currentQuantity }} / {{ props.row.maxQuantity }}
                </span>
                <span v-else-if="props.column.field === 'action'">
                  <b-button
                    v-if="isAllowRegister"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    size="sm"
                    :disabled="props.row.currentQuantity >= props.row.maxQuantity"
                    @click="onCreateStudentRegisterSubject(props.row)"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </span>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Danh sách khóa luận tốt nghiệp đã đăng ký</b-card-title>
          </b-card-header>
          <b-card-body>
            <vue-good-table
              :columns="registeredColumns"
              :rows="dataRows"
              :pagination-options="paginationOptions"
              :line-numbers="true"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'action'">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="btn-icon"
                    size="sm"
                    @click="onDeleteStudentRegisterSubject(props.row.id)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </span>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <b-alert class="custom-alert" show variant="danger">
      Bạn không đủ điều kiện để đăng ký làm Khóa Luận Tốt Nghiệp!
    </b-alert>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import { Flag } from '@/const/flag'
import moment from 'moment'

export default {
  name: 'RegisterSubject',
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      filter: {
        studentId: null,
        courseId: null,
      },
      registerColumns: [
        {
          label: 'Tên đề tài',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên Tên đề tài',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Mã đề tài',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã đề tài',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Giảng viên',
          field: 'employeeName',
          thClass: 'text-center',
        },
        {
          label: 'Chi tiết',
          field: 'description',
          width: '20%',
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          width: '10%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      registeredColumns: [
        {
          label: 'Tên đề tài',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên Tên đề tài',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Mã đề tài',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã đề tài',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Giảng viên',
          field: 'employeeName',
          thClass: 'text-center',
        },
        {
          label: 'Chi tiết',
          field: 'description',
          width: '20%',
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          width: '10%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      paginationOptions: {
        enabled: false,
      },
      courseSemesterCurrent: [],
      isAllowRegister: false,
      dataRows: [],
      dataRows1: [],
      messages: [],
      socket: null,
      isListening: null,
    }
  },

  computed: {
    ...mapGetters({
      registerDataSources: 'registerGraduationProject/registerDataSources',
      registeredDataSources: 'registerGraduationProject/registeredDataSources',
      conditionRegisterGraduationProject: 'registerGraduationProject/conditionRegisterGraduationProject',
      graduationProjectRegisterRounds: 'registerGraduationProject/graduationProjectRegisterRounds',
    }),
    courseId() {
      const { programmes, portalProgrammeId } = this.user
      if (!portalProgrammeId || programmes.length === 0) return null
      const found = programmes.find(element => element.id === portalProgrammeId)
      return found ? found.courseId : null
    },
  },

  async created() {
    this.isLoading = false
    const { programmes, portalProgrammeId } = this.user
    const found = programmes.find(element => element.id === portalProgrammeId)
    try {
      await Promise.all([
        this.getGraduationProjectRegisterRounds({
          status: 1,
          currentPage: 1,
          itemsPerPage: 10,
        }),
        this.checkConditionRegisterGraduationProject({
          studentId: found.studentClassId,
        }),
        this.getDataSourcesFromStore(),
      ])
      if (this.graduationProjectRegisterRounds.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.courseSemesterCurrent = this.graduationProjectRegisterRounds[0]
      }
      const openDate = +moment(this.courseSemesterCurrent.startDate, 'DD/MM/YYYY HH:mm')
      const closeDate = +moment(this.courseSemesterCurrent.endDate, 'DD/MM/YYYY HH:mm')
      const intTimeCurren = +moment()
      if (openDate <= intTimeCurren && closeDate >= intTimeCurren) {
        this.isAllowRegister = true
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getDataSources: 'registerGraduationProject/getDataSources',
      checkConditionRegisterGraduationProject: 'registerGraduationProject/checkConditionRegisterGraduationProject',
      createStudentRegisterSubject: 'registerGraduationProject/createStudentRegisterSubject',
      deleteStudentRegisteredSubject: 'registerGraduationProject/deleteStudentRegisteredSubject',
      getGraduationProjectRegisterRounds: 'registerGraduationProject/getGraduationProjectRegisterRounds',
    }),

    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        const { programmes, portalProgrammeId } = this.user
        const found = programmes.find(element => element.id === portalProgrammeId)
        this.filter.studentId = found.studentClassId
        this.filter.courseId = found.courseId
        await this.getDataSources(this.filter)
        this.dataRows = this.registeredDataSources
        this.dataRows1 = this.registerDataSources
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onCreateStudentRegisterSubject(data) {
      // eslint-disable-next-line no-param-reassign
      this.$swal
        .fire({
          title: 'Học phần này thuộc nhóm thay thế khóa luận tốt nghiệp',
          text: 'Bạn muốn tiếp tục đăng ký?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<i class="las la-check fs-3"></i> Đồng ý',
          cancelButtonText: '<i class="las la-times fs-3"></i> Hủy',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        .then(async result => {
          if (result.value) {
            await this.creditRegistration(data)
          }
        })
    },
    async creditRegistration(data) {
      this.isLoading = true
      const { programmes, portalProgrammeId } = this.user
      const found = programmes.find(element => element.id === portalProgrammeId)
      try {
        const response = await this.createStudentRegisterSubject({
          graduateDissertationId: data.id,
          studentClassId: found.studentClassId,
          status: 1
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getDataSourcesFromStore()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onDeleteStudentRegisterSubject(id) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa dữ liệu?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="las la-check fs-3"></i> Đồng ý',
        cancelButtonText: '<i class="las la-times fs-3"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteStudentRegisteredSubject(id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSourcesFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.list-schedule ul li {
  list-style: none;
  padding: 2px;
}
.list-schedule ul {
  padding: 0;
}
.custom-alert {
  text-align: center;
  font-size: 1.5rem;
  color: red;
}
</style>
