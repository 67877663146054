<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <b-container>
      <div class="news-detail">
        <b-row>
          <b-col cols="12">
            <b-card
              header-tag="header"
              footer-tag="footer"
            >
              <template #header>
                <div class="title-new">
                  {{ article.name }}
                </div>
              </template>
              <div class="news-description" v-html="article.description"></div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BContainer,
  BOverlay,
} from 'bootstrap-vue'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'NewsDetail',
  components: {
    BCard,
    BCol,
    BRow,
    BContainer,
    BOverlay,
  },
  props: {
    newsId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      id: '',
      isLoading: false,
    }
  },

  async created() {
    await this.getInitData()
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      article: 'article/article',
    }),
  },
  watch: {
    // listening change data init
    'newsId': async function (val) {
      if(val) await this.getArticleById(val)

    },
  },
  methods: {
    // handle even
    ...mapActions({
      getArticleById: 'article/getArticleById',
    }),

    async getInitData() {
      this.isLoading = true
      this.id = this.$route.params.newsId ? this.$route.params.newsId : this.newsId
      await this.getArticleById(this.id)
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
span.list-category {
  margin: 2px;
  color: black;
  border-right: 2px solid #ccc;
  cursor: pointer;
  font-weight: 500;
}

span.list-category:last-child {
  border-right: none;
}

span.list-category:first-child {
  margin-left: 0px;
}

.widget .wtitle {
  margin: 16px 0 12px;
  position: relative;
  font-size: 20px;
  display: inline-block;
  line-height: normal;
}

.widget .wtitle:after {
  content: "";
  height: 2px;
  background: #7367f0;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -5px;
}

.widget .wcontent a {
  display: block;
  color: #4f4f4f;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 5px;
}

.ul-tintuc {
  padding: 0px 15px;
}

.ul-tintuc .li-tintuc {
  padding-bottom: 5px;

}

.ul-tintuc .li-tintuc::marker {
  padding-bottom: 5px;

}

.title-new {
  font-size: 22px;
  color: black;
  font-weight: bold;
}

.breadcrumb {
  background-color: #ccc;
  display: inline-flex;
}

.news-detail .news-description img {
  width: 100%;
}
</style>
