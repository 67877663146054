<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <b-form>
      <b-row>
        <!-- Name Building -->
        <b-col cols="12">
          <b-form-group
            label="Nội dung file"
            label-for="name-document"
          >
            <b-form-input
              id="name-document"
              v-model="documentName"
              name="documentName"
              placeholder="Tên tòa nhà"
              :state="sateDocument"
              aria-describedby="input-1-live-feedback"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >Nội dung tài liệu không được để trống.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="File"
            label-for="file"
          >
            <b-form-file
              ref="inputFileRef"
              :placeholder="placeholder"
              @change="uploadFile"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="float-left">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="!invalidForm"
              @click="handleSaveData"
            >
              Lưu
            </b-button>
          </div>

        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BForm,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'FormDocument',
  components: {
    BForm,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BOverlay,
  },
  props: {
    dataSendForm: {
      id: null,
      rowNum: null,
      name: '',
      mediaId: null,
      mediaName: '',
      url: '',
      vgt_id: 0,
      originalIndex: 0,
    },
    modalShow: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      isLoading: false,
      documentName: '',
      documentFile: '',
      mediaIds: [],
      nameFile: null,
      placeholder: 'Chọn file để tải tài liệu...',
    }
  },

  created() {
    // init data FROM API
    if (!this.isCreated) {
      this.documentName = this.dataSendForm.name
      this.placeholder = this.dataSendForm.mediaName
    }
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      documents: 'documents/documents',
    }),
    sateDocument() {
      return !!(this.documentName && this.documentName.length > 0)
    },

    invalidForm() {
      return this.sateDocument && (this.mediaIds.length > 0 || !this.isCreated)
    },

    isCreated() {
      return !this.dataSendForm.id
    },
  },
  watch: {
    // listening change data init

  },
  methods: {
    ...mapActions({
      getDocuments: 'documents/getDocuments',
      uploadDocument: 'documents/uploadDocument',
      saveDocument: 'documents/saveDocument',
    }),
    // handle even
    async handleSaveData(e) {
      e.preventDefault()
      if (this.mediaIds) {
        const param = {
          id: this.isCreated ? null : this.dataSendForm.id,
          status: 1,
          name: this.documentName,
          mediaId: this.isCreated ? this.mediaIds[0] : this.dataSendForm.mediaId,
          delFlag: 0,
        }
        const res = await this.saveDocument(param)
        if (res.isSuccessful) {
          this.showToast('thành công', 'CheckIcon', 'success')
          this.$emit('saveDataSuccess', true)
        } else {
          this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'warning')
        }
      }
    },

    async getInitData() {
      // await this.getDocuments()
    },

    async uploadFile(e) {
      this.isLoading = true
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File tài liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        this.isLoading = false
        return
      }

      files.forEach(file => formData.append('files', file))
      this.mediaIds = await this.uploadDocument(formData)
      if (this.mediaIds.length > 0) {
        this.showToast('Upload file thành công', 'CheckIcon', 'success')
        await this.getInitData()
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
      this.isLoading = false
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
